@import '../../../styles/variable.scss';

// 교육 템플릿 등록 모달
.edu-template-add-modal {
  .question-icon {
    .anticon {
      margin-left: 5px;
      margin-right: 0 !important;
      color: $brand-green;
    }
  }

  .edu-edit-footer {
    position: relative;

    .arrow-box {
      top: -60px;
      right: 85px;
      text-align: left;
    }
  }

  .video-add-area {
    // margin-top: 16px;
    padding: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    background-color: #f9f9f9;

    &.non-click {
      pointer-events: none;
    }    

    .releative-box {
      position: relative;
    }

    .choice-box {
      position: relative;

      .choice-button-box {
        position: absolute;
        top: -25px;
        right: 0;

        .choice-btn {
          margin-left: 5px;
          padding: 2px 8px;
          color: #fff;
          font-size: 13px;
          background-color: #838383;
          border-radius: 3px;
          border: none;
        }
      }

      .choice-list {
        .ant-radio-group {
          width: 100%;

          &.warning {
            .ant-radio-inner {
              border-color: #ff1744;
            }
          }

          .ant-radio-wrapper {
            margin-right: 0;

            .ant-radio {
              margin-top: 5px;
            }

            .ant-radio+span {
              width: 100%;
              margin-right: 0;
              padding-right: 0;
            }
          }
        }
      }
    }

    .video-btn-box {
      display: flex;
      justify-content: space-between;

      .video-btn {
        width: 100%;
        height: 40px;
        color: #fff;
        background-color: #838383;
        border-radius: 5px;
        border: none;
        outline: none;
        margin: 20px 5px 5px;
      }
    }
  }

  .video-list-box {
    height: 152px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    overflow-y: auto;

    .video-list {
      padding: 5px 10px;

      .video-list-item {
        display: flex;
        padding-bottom: 5px;
        overflow-x: hidden;

        &:last-child {
          padding-bottom: 0;
        }

        .delete-icon {
          color: $red;
          height: 22px;
          font-size: 16px;
          margin-right: 5px;
          cursor: pointer;

          .anticon {
            margin: 0;
          }
        }

        .list-info {
          display: grid;
          cursor: pointer;
          flex: 1;
        }

        .video-title {
          font-weight: 700;

          .dot {
            font-weight: 700;
            padding-right: 5px;
          }

          .running-time {
            color: #838383;
            font-weight: normal;
            font-size: 13px;
          }
        }
      }
    }
  }

  .file-select-area {
    position: relative;

    .file-input-area {
      width: 100%;
      height: 60px;
      margin-top: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #d6d6d6;
      border-radius: 5px;

      label {
        max-width: calc(100% - 20px);
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #919191;
        font-size: 13px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      &.error {
        border-color: $red;
      }
    }

    .arrow-box.top {
      top: -36px;
    }
  }

  .footer-btn {
    position: relative;

    &.prev {
      .anticon {
        margin-right: 20px;
      }
    }

    &.ok {
      color: #fff;
      border-color: #11a991 !important;
    }

    &.next {
      color: #fff !important;
      background-color: #11a991 !important;
      border-color: #11a991 !important;

      .anticon {
        margin-left: 20px;
        margin-right: 0;
      }
    }
  }

  .none {
    display: none;
  }

  .form-button-group {
    .edu-add-edit-button-group {
      .ant-radio-button-wrapper {
        height: 40px;
      }
    }
  }
}

// 영상보기 모달
.edu-template-video-modal {
  .video-player-box {
    position: relative;

    .video-header {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      color: #eee;
      background-color: #000;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      z-index: 2;

      .video-header-left {
        width: 350px;
        text-wrap: nowrap;

        .video-title {
          font-size: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .video-sub-title {
          font-size: 13px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .video-header-right {
        font-size: 13px;
        text-align: right;
      }
    }

    .video-player {
      margin-top: 35px;

      .video-js:not(.vjs-youtube) {
        position: relative;
        top: 28px;
        margin-bottom: 30px;
      }

      &.youtube {
        margin: 0;
      }

      .vjs-youtube {
        border: 1px solid #dfdfdf;
      }
    }
  }

  .footer-btn {
    &.next {
      .anticon {
        margin-left: 10px;
      }
    }
  }
}

.edu-template-popover {
  font-size: 13px;

  .ant-popover-content {
    max-width: 330px;
  }

  .caution-message {
    margin-top: 5px;
    color: $dim-grey;
  }
}
.edu-edit-modal {
  .extra-area {
    justify-content: end;
  }

  .form-field-wrap {
    .ant-select-selector {
      height: 100% !important;
      margin: 0;
      padding: 5px 40px 5px 7px !important;
      text-indent: 0;
    }

    .ant-select-arrow {
      top: 21px;
      right: 15px;
    }
  }
}

.edu-sidebar-popover {
  .ant-popover-inner-content {
    padding-right: 30px !important;

    img {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.common-content-list {
  width: 100%;
  height: calc(100% - 70px);
  min-height: 100px;
  border-top: 2px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  overflow-y: auto;

  &.half {
    height: calc(100% - 360px);
  }
  
  &.full {
    height: 100%;
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;

    .content-list-item {
      display: flex;
      align-items: center;
      height: 47px;
      padding: 0 5px;
      color: #6e6e6e;
      border-bottom: 1px solid #d6d6d6;
      cursor: pointer;

      &.selected {
        background-color: #ededed;
      }

      &:hover:not(.selected) {
        background-color: #f9f9f9;
      }

      &.block-click {
        pointer-events: none;
      }

      div {
        &:first-child {
          padding: 0 5px;
        }
      }

      .check-div {
        width: 25px;
        line-height: 14px;
        padding: 0 !important;
      }

      .list-check {
        // width: 25px;
        // margin-left: 5px;
        // width: 14px;
        // height: 14px;
        width: 20px;
        height: 20px;
        margin-left: 3px;

        &.image {
          position: relative;
          top: 1px;
        }

        .ant-checkbox-inner {
          // width: 14px;
          // height: 14px;
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
        }

        // .ant-checkbox-inner {
        //   width: 14px;
        //   height: 14px;
        //   background-color: #dbdbdb;
        //   border: none;
        // }

        // .ant-checkbox-checked {
        //   .ant-checkbox-inner {
        //     background-color: #a7a7a7;

        //     &::after {
        //       top: 0;
        //       left: 0;
        //       width: 100%;
        //       height: 100%;
        //       background: url(/img/exam/list_check_on.png) no-repeat center;
        //       border: none;
        //       border-radius: 3px;
        //       transform: none;
        //     }
        //   }
        // }
      }
    }
  }

  .no-content-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    .no-content-img {
      margin-bottom: 25px;
    }

    .no-content-title {
      color: #333;
      font-size: 22px;
      font-weight: 800;
      letter-spacing: -2px;
    }

    .no-content-sub-title {
      margin-top: 5px;
      color: #3c3c3c;
      font-size: 15px;
    }
  }
}
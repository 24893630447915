.edu-pdfViewer {
  height: calc(100vh - 320px);
  background-color: #323639;
  color: #ffffff;

  &.full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .pdf-contents {
      height: calc(100vh - 40px) !important;
    }
  }

  &.preview {
    height: 370px;
    margin-top: 5px;
    border: 1px solid #dfdfdf;

    .pdf-contents {
      height: calc(100% - 45px) !important;
    }
  }

  .pdf-control-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    .pdf-control-button {
      font-size: 16px;
      border: none;
      background: none;
    }

    .pdf-control-text {
      min-width: 50px;
      text-align: center;
      margin: 0 10px;
      background-color: #52565967;
      cursor: default;

      &.scale {
        min-width: 30px;
      }
    }

    .anticon {
      margin: 0;
    }
  }

  .separator {
    color: #525659;
    margin: 0 10px;
  }

  .pdf-contents {
    position: relative;
    left: 0;
    height: calc(100% - 45px) !important;
    display: flex;
    justify-content: center;
    background-color: #525659;

    .react-pdf__Document {
      height: 100%;
      overflow: auto;

      .react-pdf__Page {
        height: inherit;
      }
    }
  }
}
@import '../../styles/variable.scss';

.header {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  font-size: 15px;
  background-color: #2D313A;

  .header-logo {
    position: absolute;
    top: 0;
    left: 60px;
    display: flex;
    align-items: center;
    width: 118px;
    height: inherit;
    // background: url(/img/logo/mf_logo_white_small_v.png) no-repeat center;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .header-action-buttons {
    position: absolute;
    top: 0;
    left: 280px;
    display: flex;
    height: inherit;
    align-items: center;

    button {
      margin: 0 5px 0 0;
    }
  }

  .header-router-link {
    position: absolute;
    // left: 550px;
    left: 500px;
    display: flex;
    min-width: 580px;
    height: 60px;

    .header-menu {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 96px;
      margin: 0 5px;
      padding: 10px 20px;
      color: #fff;
      font-size: 15px;
      text-decoration: none;
      opacity: 0.6;

      &.active,
      &:hover {
        bottom: 4px;
        padding-bottom: 2px;
        box-shadow: 0px 4px 0px #14ae96;
        opacity: 1;
      }
    }
  }

  .header-settings {
    position: absolute;
    top: 0;
    right: 60px;
    height: inherit;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    &.left {
      left: 20px;
    }

    &.close {
      transform: rotate(90deg);
    }

    .header-btn {
      min-width: 70px;
      height: 34px;
      line-height: 33px;
      margin-left: 15px;
      color: #fff;
      text-align: center;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 5px;
      font-weight: bold;
      padding: 0 19px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &.ant-dropdown-trigger {
        line-height: 30px;
      }

      .anticon {
        padding-right: 10px;
      }

      &:hover {
        border-color: $brand;
        box-shadow: 1px 1px 5px $white;
        color: $brand;
      }
    }

    &.saxa {
      .header-btn:hover {
        border-color: $saxa-brand-color;
        color: $saxa-brand-color;
      }
    }

    .header-icon-area {
      width: 34px;
      height: 34px;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-left: 18px;
      }

      &.no-active {
        .point-mark {
          position: absolute;
          top: 7px;
          right: 0;
        }

        .user {
          box-shadow: 0 0 0 3px #ae1414;
        }

        .no-active-tooltip {
          display: none;
          position: absolute;
          top: 65px;
          right: 0;
          margin-right: -50px;
          padding: 14px 22px;
          color: #fff;
          font-size: 14px;
          text-align: center;
          white-space: nowrap;
          background-color: #ae1414;
          border-radius: 5px;

          &::before {
            content: '';
            position: absolute;
            top: -10px;
            right: 73px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 10px solid #ae1414;
          }

          .small-text {
            margin-top: 2px;
            font-size: 12px;
          }
        }

        &:hover {
          .no-active-tooltip {
            display: block;
            z-index: 1;
          }
        }

        &.ant-dropdown-open {
          .no-active-tooltip {
            display: none;
            z-index: 1;
          }
        }
      }

      &.change-service {
        width: 100%;
        margin: 0;
      }

      .icon {
        &.help {
          width: 24px;
          height: 24px;
        }

        &.user {
          width: 34px;
          border-radius: 5px;
        }

        &.service {
          width: 16px;
          height: 20px;
        }
      }

      .service-name {
        margin: 0 9px;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

.header-dropdown {
  animation-duration: 0s;

  .ant-dropdown-arrow {
    top: 7px;
  }

  .ant-dropdown-menu {
    padding: 8px 5px;
    border-radius: 3px;
    border: 1px solid rgba(175, 175, 175, 0.2);
    box-shadow: 0 2px 3px rgba(175, 175, 175, 0.3);

    .ant-dropdown-menu-item {
      transition: none;
      padding: 5px 15px;

      &.center {
        text-align: center;
      }

      .anticon {
        position: relative;
        top: 1px;
        margin-right: 10px;
        font-size: 14px;
      }

      a {
        text-decoration: none;
      }
    }

    .divider {
      margin: 5px 0;
      border-bottom: 1px solid #afafaf;
      opacity: 0.3;
    }
  }

  &.header-share {
    .ant-dropdown-menu {
      min-width: 300px;
      max-height: 300px;
      overflow-y: auto;
      padding: 5px 10px;

      .ant-dropdown-menu-item {
        display: flex;
        align-items: center;
        height: 75px;
        padding: 0;
        border-bottom: 1px solid #f2f2f2;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

// 머드픽스 정보 모달
.info-modal {
  &.ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 70px 60px !important;

        .version-logo {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          img {
            height: 59px;
          }

          span {
            margin-left: 5px;
            font-size: 20px;
            font-weight: 700;
          }
        }

        .version-text {
          font-weight: 700;
        }

        .copyright {
          margin-top: 15px;
          font-size: 12px;
        }
      }

      .ant-modal-footer {
        display: none;
      }
    }
  }
}

// 서비스 변경 리스트
.change-service-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 13px;

  img {
    padding-right: 13px;
  }

  .service-item {
    &.selected {
      color: #00b5ad;
    }

    .service-main-text {
      font-size: 15px;
      font-weight: 700;

      img {
        margin-left: 5px;
      }
    }

    .service-sub-text {
      color: #333;
      font-size: 12px;
    }
  }
}

.pie-chart-base {
  position: absolute;
  top: 0;
  right: 0;

  .center-value {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .value-text {
      font-size: 14px;
      font-weight: 700;
    }

    .value-percent {
      font-size: 9px;
      font-weight: 700;
    }
  }

  &.dashboard-pie {
    top: 15px;
    right: 15px;
  }
}
.target-scroll-item {
  .no-tag-text {
    display: flex;
    align-items: center;
    color: #aaa;
    padding: 0 10px !important;
  }

  table {
    width: 100%;
    display: flex;
    align-items: center;

    td {
      padding: 0 1em;

      &.target-info {
        display: flex;
        align-items: center;
        padding: 0;
      }

      .email-column {
        width: 200px !important;

        .anticon-user {
          margin-left: 5px;
        }
      }

      .list-tag {
        max-width: 500px;
        display: block !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.tag-max-width {
  max-width: 200px;
}

// 대상자 리스트 소속/직급 팝오버
.division-popover {
  .text {
    min-width: 30px;
  }
}

// 대상자 리스트 태그 팝오버
.tag-popover {
  .tag-popover-content {
    .title {
      font-size: 13px;
      font-weight: 700;
    }

    .content {
      padding-top: 5px;
    }
  }
}

// 대상자 우측 상단 버튼
.target-header-btns {
  display: flex;

  .target-btns {
    display: flex;
    margin-left: 10px;

    .round-grey-button {
      margin: 0;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

// 대상자 리스트
.target-content-wrap {
  height: calc(100% - 50px);

  .search-filter-wrap {
    .extra-buttons {
      .round-grey-button {
        .all-icon {
          width: 16px;
          height: 16px;
          line-height: 16px;
          margin-right: 5px;
          padding-right: 1px;
          color: #fff;
          font-size: 10px;
          text-align: center;
          border-radius: 3px;
          background-color: #6e6e6e;
        }

        &.brand-green {
          color: #14ae96;
          border-color: #14ae96;

          .all-icon {
            background-color: #14ae96;
          }
        }
      }
    }
  }

  .infinite-scroll-component__outerdiv {
    width: fit-content;
    min-width: 100%;

    .infinite-scroll-component {
      overflow: initial !important;
    }
  }
}

// 대상자 태그 formSelectField
.ant-select.tag-select, .form-wrap.tag {
  margin-top: 0 !important;

  .ant-select-selector {
    height: 100%;
    margin: 0;
    padding: 5px 60px 5px 7px;
    text-indent: 0;

    .ant-tag-close-icon {
      display: none !important;
    }

    .anticon {
      margin-right: 0;
    }
  }

  .ant-select-arrow {
    top: 50%;
  }

  .anticon {
    margin-left: 5px;
  }

  .tag-color-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-top: 1px;
    border-radius: 4px;
  }
}

// 대상자 상세
.target-detail {
  .detail-title {
    &.merge {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .anticon {
        margin-left: 10px;
        color: #797979;
        cursor: pointer;
      }
    }
  }

  .detail-contents {
    .detail-row {
      &.target-row {
        align-items: center !important;
      }

      &.target-tag {
        padding: 0 !important;

        .edit-btns {
          height: 100%;

          .edit-btn {
            height: inherit;
          }
        }

        .ant-select-selector {
          width: calc(100% - 52px);
          height: 100% !important;
          min-height: 40px;
          padding: 3px 7px !important;
          text-indent: 0;

          .ant-select-selection-search {
            margin: 0;
          }
        }

        .ant-select-item-option-content {
          display: block;
        }
      }

      &.merge {
        flex-direction: column;
        align-items: flex-start !important;
      }

      .detail-value {
        position: relative;

        .edit-text {
          word-wrap: break-word;
        }
      }

      .target-info-edit {
        display: flex;
        width: calc(100% - 135px);

        .detail-value {
          width: 100% !important;
        }
      }

      form {
        position: relative;
        display: flex;
        width: 100%;

        .form-field-wrap {
          width: 100%;

          input {
            height: inherit;
            margin: 0;
            padding: 3px 7px;
            text-indent: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .target-form {
        left: -7px;

        .form-wrap {
          width: calc(100% - 38px);
        }

        .arrow-box {
          top: 33px;
          bottom: initial;
        }
      }

      .memo-form {
        .form-wrap {
          width: calc(100% - 50px);
          height: 100px;
        }

        .memo-textarea {
          width: calc(100% - 50px);
          border-color: #ddd;
          border-radius: 5px;
          padding: 5px;
          outline: none;
          resize: none;
        }
      }

      .edit-btns {
        position: absolute;
        right: 0;
        display: flex;

        .edit-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          background-color: #f6f6f6;
          border: 1px solid #ddd;
          cursor: pointer;

          &:last-child {
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .exam-history {
        width: 100%;

        .exam-history-list-item {
          padding: 7px 0 5px;
          border-bottom: 1px solid #f2f2f2;

          .exam-history-title {
            justify-content: space-between;

            .title {
              font-weight: 700;
              max-width: 50%;
            }

            .period-text {
              margin-left: 5px;
              color: #b2b2b2;
              font-size: 12px;
            }

            .exam-arrow-btn {
              cursor: pointer;
            }
          }

          .exam-history-content {
            display: flex;
            justify-content: space-between;
            margin-top: 7px;

            .status-area {
              display: flex;
            }

            .grey-btn {
              min-width: 55px;
              height: 18px;
              line-height: 16px;
              margin-right: 5px;
              padding: 0 6px;
              color: #afafaf;
              font-size: 11px;
              text-align: center;
              border: 1px solid #afafaf;
              border-radius: 3px;
            }

            .declare {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 9px;

              img {
                width: 10px;
                height: 10px;
                margin-right: 5px;
              }
            }
          }

          .exam-history-detail {
            margin: 13px 0 3px;
            padding: 18px 15px;
            background-color: #f6f6f6;
            border-radius: 5px;
            font-size: 12px;

            .history-detail-title {
              padding-bottom: 5px;
              font-weight: 700;
            }

            .history-detail-item {
              .detail-row {
                padding: 5px 0 0 10px;
                min-height: 100%;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .editable {
      display: inline-block !important;
      padding: 3px 31px 3px 7px !important;
      border-radius: 5px;
      border: 1px solid transparent;

      &:hover {
        border-color: #ededed;
        cursor: pointer;

        .edit-icon {
          display: flex;

          &.border {
            border: 1px solid #ededed;
          }
        }
      }

      &.target-tag {
        width: 100%;
      }

      &.memo {
        width: 100%;
        height: 100px;
      }
    }

    .edit-text {
      display: inline-block;
      width: 100%;

      .memo-text {
        max-height: 90px;
        overflow-y: auto;
        white-space: pre-line;
        word-break: break-all;
      }

      .ant-tag {
        pointer-events: none;

        .anticon {
          display: none;
        }
      }
    }

    .edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 100%;
      border-left: 1px solid #ddd;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #f6f6f6;
    }

    .anticon {
      margin-right: 0;
    }

    .no-data {
      .anticon {
        margin-right: 5px;
      }
    }

    .edu-step-area {
      display: flex;
      align-items: center;
      height: 31px;
    }
  }
}

// 대상자 상세 차트
.target-exam-chart {
  margin: 4px 0;
  padding: 20px 22px 19px 27px;
  background-color: #fcfcfc;
  border: 1px solid #e8e8e8;
  border-radius: 5px;

  &.merge {
    margin-top: 10px;
    padding: 10px 10px 10px 5px;

    .chart-contents {
      margin: 0;
    }
  }

  .chart-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #292929;

    img {
      cursor: pointer;
    }
  }

  .chart-contents {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .chart-text-area {
      font-size: 13px;
      flex: 1;

      .chart-row {
        display: flex;
        justify-content: space-between;
        margin: 10px 5px;

        &.sub {
          margin-top: 5px;

          .title {
            height: 15px;
            margin: 0 0 3px 20px;
            padding: 0;
            font-size: 12px;
          }
        }

        .title {
          display: flex;
          align-items: center;
          color: #434343;

          .color-dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 4px;
            margin-top: 1px;
            border-radius: 4px;
          }

          .text {
            width: 95px;
            margin-right: 5px;
          }
        }

        .value {
          min-width: 40px;
          text-align: right;
        }
      }
    }

    .chart-graph-area {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      padding-top: 5px;
      margin-left: 10px;

      .infection-icon {
        position: absolute;
        top: 43px;
      }

      .infection-percent-text {
        width: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
        text-align: center;

        .text {
          width: 100%;
          word-break: break-word;
          color: #3f3f3f;
          font-size: 14px;
        }

        .value {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .percent-number {
            color: #f01e2f;
            font-size: 27px;
          }

          .percent {
            height: 12px;
            margin-left: 3px;
            color: #666;
            font-size: 12px;
          }
        }
      }
    }
  }
}

// 태그 일괄 추가/삭제 모달
.target-tag-modal {
  .modal-border-box {
    padding: 10px 20px 15px !important;

    .input-title {
      margin-bottom: 10px;
    }
  }

  .tag-select {
    .ant-select-selector {
      padding: 3px 40px 3px 7px !important;
      height: 100% !important;

      .ant-select-selection-search {
        margin-inline-start: 0;
      }
    }
  }
}

// 대상자 일괄 등록 모달
.target-upload-modal {
  .excel-format-download {
    button {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 150px;
      height: 35px;
      padding: 9px 20px;
      color: #fff;
      background-color: #8ac0b6;
      border: none;
      border-radius: 18px;

      &:hover {
        box-shadow: 1px 1px 5px #616161;
      }
    }

    .download-text {
      margin-left: 5px;
      font-size: 12px;
      font-weight: 700;
    }
  }

  // 파일 업로드
  .file-input-area {
    position: relative;
    margin-top: 20px;
    padding: 15px;
    border: 2px dashed #cfcfcf;
    border-radius: 10px;

    label {
      font-size: 14px;
    }

    input {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      position: absolute;
    }

    .upload-hint {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin: 10px 0;
      }
    }

    .file-label {
      .accept-file-area {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        img {
          width: 60px;
          height: 60px;
        }

        .file-info {
          position: relative;
          margin-left: 25px;
          text-align: right;

          .file-name {
            max-width: 240px;
            font-size: 17px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .file-size {
            font-size: 15px;
          }
        }
      }

      .reject-file-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        word-break: break-all;

        .info-message {
          margin-top: 10px;
          color: #838383;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  // 업로드 결과
  .file-upload-result {
    margin-top: 10px;

    // 업로드 요약
    .upload-summary-title {
      margin-bottom: 3px;
      font-size: 16px;
      font-weight: 700;

      .anticon {
        margin-right: 5px;
      }
    }

    .upload-summary-content {
      margin: 0 15px;
      font-size: 12px;
    }

    // 업로드 결과 상세
    .upload-detail {
      margin: 0 15px;
      font-size: 12px;

      .result-area {
        .result-title {
          margin-top: 10px;
          font-weight: 700;
        }

        .result-content {
          max-height: 100px;
          margin-top: 3px;
          padding: 5px 8px;
          border: 1px solid #ddd;
          border-radius: 3px;
          overflow: auto;

          .result-row {
            display: flex;

            .left {
              width: 50px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .target-exam-chart {
    .chart-contents {
      .chart-text-area {
        font-size: 0.7em;

        .chart-row {
          &.sub {
            .title {
              font-size: 0.7em;
            }
          }

          .title {
            .text {
              width: 80%;
            }

            .value {
              min-width: 0;
            }
          }
        }
      }

      .chart-graph-area {
        margin-left: 5px;
      }
    }
  }
}
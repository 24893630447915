.reg-date-fromnow {
  display: none;
}

.passwd-update-date-fromnow {
  display: none;
}

.from-now-reg-date {
  .reg-date-fromnow {
    display: unset !important;
  }

  .reg-date-normal {
    display: none !important;
  }
}

.from-now-passwd-update-date {
  .passwd-update-date-fromnow {
    display: unset !important;
  }

  .passwd-update-date-normal {
    display: none !important;
  }
}

.tag-cell {
  height: 51px;

  .tag-container {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .TagItem-wrapper {
    display: inline-block;
    white-space: nowrap;
  }

  .tag-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
}

.targetStatus {
  .targetInfo-area {
    display: flex;
  }
}

.cell-tag-inner {
  display: block;
}

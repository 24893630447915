$dash-point-red: #ce0808;
$dash-title: #3b4758;
$dash-text: #586a84;
$table-header: #7d8fa9;
$table-line: #eef0f4;

.dashboard-bg-layout {
  width: 100%;
  height: 100%;
  background-color: #eef0f4;
  // font-family: 'Roboto' !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  overflow: auto;

  &.grey-theme {
    background-color: #eef0f7;
  }

  .dash-inner-layout {
    .main-title {
      color: $dash-title;
      font-size: 24px;
      font-weight: 700;
      padding: 75px 0 10px;
    }

    .dash-flex {
      display: flex;
      // width: 1660px;
      align-items: flex-end;
      gap: 20px;

      &:last-child {
        padding-bottom: 75px;
      }
    }

    .result-group {
      position: relative;
      display: flex;
      width: 220px;
      height: 136px;
      margin-bottom: 20px;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.1);

      .result-group-title {
        color: $dash-text;
        font-size: 14px;
      }

      .group-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .group-title {
        color: $dash-title;
        font-size: 16px;
        font-weight: 700;
      }

      .total-score {
        position: absolute;
        text-align: center;
        right: 0;
        top: 0;

        .total-score-img {
          position: relative;
          right: 12px;
          top: 28px;
        }

        .total-level {
          position: relative;
          line-height: 1;
          right: 13px;
          top: -14px;
          color: #000;

          .level-point {
            color: $dash-point-red;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .group-point-text {
        color: $dash-title;
        font-size: 24px;
        font-weight: 700;
      }

      .group-sub-text {
        color: $dash-text;
        font-size: 12px;
      }

      .color-red {
        color: $dash-point-red;
        font-weight: 700;
      }

      &.left {
        width: 460px;
        height: 406px;
        justify-content: initial;
      }

      &.right {
        display: flex;
        width: 1180px;
        height: 406px;
        flex-direction: column;
        align-items: flex-start;
      }

      .infection-target-table {
        width: 100%;
        margin-top: 15px;
        padding: 10px 20px;
        color: $dash-text;
        table-layout: fixed;
        cursor: default;

        tr {
          &.table-row {
            border-top: 1px solid $table-line;
            align-items: center;

            .target-name {
              color: $dash-title;
              font-size: 16px;
              font-weight: 700;
            }

            .target-division {
              font-size: 10px;
              font-weight: 300;
            }
          }
        }

        th {
          color: $table-header;
          font-size: 12px;
          font-weight: 500;
          padding: 10px 20px;

          &:first-child {
            width: 200px;
          }
        }

        td {
          padding: 10px 25px;
          font-weight: 700;

          &:first-child {
            width: 200px;
          }
        }
      }

      .dash-badge {
        border-radius: 9px;
        background: #141515;
        padding: 1px 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }

      .status-item {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 800;
        line-height: 16px;
        margin-left: 5px;

        img {
          margin-right: 2px;
        }
      }
    }

    .dash-exam-status {
      .result-group {
        height: 503px;
      }

      .exam-border-box {
        display: flex;
        margin-top: 15px;
        padding: 20px;
        justify-content: center;
        gap: 10px;
        flex: 1 0 0;
        flex-direction: column;
        align-self: stretch;
        flex-wrap: wrap;
        border-radius: 8px;
        border: 1px solid #e7e7e7;
        box-shadow: 0px 1px 3px 0px rgba(96, 108, 128, 0.05);
        color: $dash-title;
        font-size: 12px;
        font-weight: 600;

        .space-between {
          display: flex;
          justify-content: space-between;
        }

        .category-area {
          max-width: 250px;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;

          .category-label {
            font-size: 10px;
            font-weight: 600;
            padding: 3px 8px;
            border-radius: 16px;
            margin-right: 5px;
            display: inline-block;
            cursor: default;
          }
        }
      }
    }

    .dash-exam-templates {
      .result-group {
        height: 503px;
      }
    }

    .dash-years {
      .line-graph-box {
        .dashboard-legend {
          display: flex;
          color: $dash-text;
          position: absolute;
          right: 0;
          top: -97px;

          .legend-item {
            display: flex;
            align-items: center;
            padding-left: 22px;

            .color-circle {
              width: 12px;
              height: 12px;
              border-radius: 100%;
              background-color: #000;
              margin-right: 5px;
            }
          }
        }

        text {
          font-size: 12px;
        }
      }
    }
  }

  .dash-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 25px;
    text-align: center;
    cursor: default;
    outline: none;
    user-select: none;

    .react-table-th {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 34px;
      color: $table-header;
      font-size: 12px;
      font-weight: 500;
      padding: 0 30px;
      cursor: pointer;

      .img-asc-ordering,
      .img-desc-ordering {
        position: relative;
        left: 6px;

        &.active {
          border-radius: 5px;
          background-color: #eef0f4;
          padding: 4px;
        }
      }
    }

    .react-table-td {
      color: $dash-text;

      &:first-child {
        text-align: left;
        color: $dash-title;
        font-weight: 700;
      }

      &:last-child {
        text-align: right;
      }
    }

    .rt-tr-group {
      display: flex;
      height: 76px;
      padding: 18px 6px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-top: 0.5px solid $table-line;
    }

    .dash-total-progress {
      display: flex;
      justify-content: center;

      .ant-progress {
        width: 300px;
        margin-right: 20px;

        .ant-progress-inner {
          background-color: #eef0f4;
        }
      }
    }
  }
}

.dashboard-custom-tooltip {
  color: #000;
  border-radius: 15px;
  border: 1px solid #666;
  background: #f1f1f1;
  padding: 10px 15px;

  .tooltip-label {
    font-size: 12px;
    font-weight: 700;
  }

  .tooltip-group {
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .tooltip-content {
    font-size: 11px;
  }
}
